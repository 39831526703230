import React from "react"
import "./App.css"

const Heading = () => {
    return (
      <div className="Heading">
        <header className="App-header">
          <h1>Video Recommendation</h1>
          <p>Hello World</p>
        </header>
      </div>
    );
}

export default Heading;